import axios from '../api.js';
import * as config from '../config';

export default {
    
    // get affiliates list data
    getAllAffiliates: () =>
    axios.get(config.baseURL + 'getAllAffiliates'),

    // get customer list data
    getAllCustomers: () =>
    axios.get(config.baseURL + 'getAllCustomers'),

    // get customer list data new
    getAllCustomersNew: () =>
    axios.get(config.baseURL + 'getAllCustomersNew'),
 
    // get SAP CDC user's data
    getUsersInformation: () =>
    axios.get(config.baseURL + 'getUsersInformation'),

    // get SAP CDC user's data based on email 
    getsapCDCInfo: (req) =>
    axios.post(config.baseURL + 'getsapCDCInfo',req),

    // get postings table data
    getPostingSessionsForLoggedInUser: (req) =>
    axios.post(config.baseURL + 'getPostingSessionsForLoggedInUser', req),

    // send Feedback email
    sendFeedback: (req) =>
    axios.post(config.baseURL + 'sendFeedback', req),
    
    // get posting session details based on Id
    getPostingSessionDetailsById: (params) =>
    axios.get(config.baseURL + 'getPostingSessionDetailsById/' + params),
    
    // delete the posting 
    deletepostingSession: (params) =>
    axios.post(config.baseURL + 'deletepostingSession', params),
    
    // update the report request
    updateReportRequest: (params) =>
    axios.post(config.baseURL + 'updateReportRequest', params),
    
    // get  detailedreport requests table data
    getReportRequestsForLoggedInUser: (req) =>
    axios.post(config.baseURL + 'getReportRequestsForLoggedInUser', req),

    // save/update SAP CDC user data
    saveUsersInformation: (req) =>
    axios.post(config.baseURL + 'saveUsersInformation', req),

    // activate/deactivate the SAP CDC user 
    deleteCustomer: (req) =>
    axios.post(config.baseURL + 'deleteCustomer', req),

    activateordeactivatelang: (req) =>
    axios.post(config.baseURL + 'activateordeactivatelang', req),

    getLanguageData: () =>
    axios.get(config.baseURL + 'getLanguageData'),

    getTarget: () =>
    axios.get(config.baseURL + 'getTarget'),

    getTargetYear: () =>
    axios.get(config.baseURL + 'getTargetYear'),

      // save target data
      saveTarget: (req) =>
      axios.post(config.baseURL + 'saveTarget', req),

       // get target by id data
       getTargetByID: (req) =>
       axios.post(config.baseURL + 'getTargetByID', req),

        // update target data
        UpdateTarget: (req) =>
      axios.post(config.baseURL + 'UpdateTarget', req),

       // save target data
       DeleteTarget: (req) =>
       axios.post(config.baseURL + 'DeleteTarget', req),

       getUsersData: (req) =>
       axios.post(config.baseURL + 'getUsersData', req),

       getUsersDataNew: () =>
       axios.get(config.baseURL + 'getUsersDataNew'),

      // save Users Alert data
      saveUserAlert: (req) =>
      axios.post(config.baseURL + 'saveUserAlert', req),
   
      // Delete User Notification data
      DeleteUserAlert: (req) =>
      axios.post(config.baseURL + 'DeleteUserAlert', req),
}