import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import postingservice from '../../services/postingservice.js';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import commondataservice from '../../services/commondataservice.js';

const NoDataIndication = () => (
    <div className="spinner">
        <div className="text-center">No Records Found</div>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
    </div>
);

let options = {
    showTotal: true,
    sizePerPageList: [{
        text: '25', value: 25
    }, {
        text: '50', value: 50
    }, {
        text: '75', value: 75
    }]
};

export default class Target extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            customerList: [],
            userirn: '',
            data: [],
            showHide4: false,
            showHide2: false,
            showHide: false,
            deletedata: [],
            deleteirn: '',
            activeflag: '',
            value: '',
            userid: commondataservice.getUserlogin(),
            useriddata: '',
            selectedcustomerOption: [],
            customerirndata: [],
            affiliateirndata: [],
            selectedMonthOption: [],
            TargetYear: [],
            Duration: '',
            Year: '',
            Month: '',
            Quarter: '',
            HalfYearly: '',
            ButtonSave: 'Save',
            customername: '',
            customervalue: "",
            irn: "",

        };
        let datauser = commondataservice.getUserlogin();
       // this.setState({ userid: datauser.userid });
        // get Customers list New
        postingservice.getAllCustomersNew()
            .then((response) => {
                //handle success
                this.setState({ customerList: response.data });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                //handle error
                this.setState({ isLoading: false });
            });

        // get getTargetData
          postingservice.getTarget()
          .then((response) => {
            console.log(response)
       // handle success
             this.setState({ data: response.data });
        this.setState({ isLoading: false });
         })
         .catch((error) => {
       // handle error
        this.setState({ isLoading: false });
         });

           // get getTargetYearData
           postingservice.getTargetYear()
           .then((response) => {
             console.log(response)
        // handle success
              this.setState({ TargetYear: response.data });
         this.setState({ isLoading: false });
          })
          .catch((error) => {
        // handle error
         this.setState({ isLoading: false });
          });

    }


    // define table columns
    columns = [

        {
            dataField: 'customername', text: 'Customer', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "18%" };
            }
        },

        {
            dataField: 'target', text: 'Target', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" };
            }
        },
        {
            dataField: 'durationdescr', text: 'Duration', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" };
            },
            formatter: (cellContent, row) => {
                if(row.durationdescr === "M") {
                    return (
                               "Monthly"                  
                            )
                }
                else if (row.durationdescr === "Y") {
                    return (
                        "Year"                       )
                }
                else if(row.durationdescr === "Q") {
                    return (
"Quarterly"                    )
                }
                else if(row.durationdescr === "H") {
                    return (
"Halfyearly"                    )
                }
            },
        },
        {
            dataField: 'yearduration', text: 'Year', sort: true,
            headerStyle: (colum, colIndex) => {

                return { width: "8%" };
            }
        },
        {
            dataField: 'subdurationdescr', text: 'Subduration', sort: true,
            headerStyle: (colum, colIndex) => {

                return { width: "8%" };
            },
            formatter: (cellContent, row) => {
                if(row.subdurationdescr.trim() === "1") { return ("January") 
                }
                else if (row.subdurationdescr.trim() === "2") {return ("Febuary")
                }
                 else if(row.subdurationdescr.trim() === "3") {return ("March")
                }
                else if(row.subdurationdescr.trim() === "4") {return ("April")
                }
                else if(row.subdurationdescr.trim() === "5") { return ("May")
                }
                else if(row.subdurationdescr.trim() === "6") {return ("June")
                }
                else if(row.subdurationdescr.trim() === "7") {return ("July")
                }
                else if(row.subdurationdescr.trim() === "8") {return ("August")
                }
                else if(row.subdurationdescr.trim() === "9") {return ("September")
                }
                else if(row.subdurationdescr.trim() === "10") {return ("October")
                }
                else if(row.subdurationdescr.trim() === "11") {return ("November")
                }
                else if(row.subdurationdescr.trim() === "12") {return ("December")
                }
                else if(row.subdurationdescr.trim() === "4") {return ("April")
                }
                else if(row.subdurationdescr.trim() === "Q1") {return ("Quarter 1")
                 }
                else if(row.subdurationdescr.trim() === "Q2") {return ("Quarter 2")
                 }
                 else if(row.subdurationdescr.trim() === "Q3") {return ("Quarter 3")
                 }
                  else if(row.subdurationdescr.trim() === "Q4") {return ("Quarter 4")
                }
                else if(row.subdurationdescr.trim() === "H1") {return ("First Half")
                 }
                else if(row.subdurationdescr.trim() === "H2") {return ("Second Half")
               }
                else if(row.subdurationdescr.trim() === "") {return ("NA")
                }
                else{return row.subdurationdescr;
                }
            },
        },
        {
            dataField: "",
            text: 'Edit',
            align: 'center',
            headerAlign: (column, colIndex) => 'center',
            formatter: (cellContent, row) => {
                return (
                    <button type="button" className="btn btn-outline-success" onClick={() =>  this.EditClick(row.irn, row.customerirn, row.target, row.durationdescr, row.yearduration, row.subdurationdescr)} >&nbsp;&nbsp;Edit&nbsp;&nbsp;</button>
                )
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" };
            }
        },
        {
            dataField: "",
            text: 'Delete',
            align: 'center',
            headerAlign: (column, colIndex) => 'center',
            formatter: (cellContent, row) => {
                return (

                    <button type="button" className="btn btn-outline-danger" onClick={() => this.handleModal1ShowHide(row.irn)}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</button>
                )
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" };
            }
        }
    ];

    // sort data
    sortIt = sortBy => (a, b) => {
        if (a[sortBy] > b[sortBy]) {
            return -1;
        } else if (a[sortBy] < b[sortBy]) {
            return 1;
        }
        return 0;
    }


    handleMonthChangeselect(e) {
        const selectedDuration = e.target.value;
        this.setState({ Duration: selectedDuration });
        //this.setState({ Duration: selectedDuration });
        console.log(this.state.Duration)

    }

    handleYearChangeselect(e) {
        const selectedDurationYear = e.target.value;
        this.setState({ Year: selectedDurationYear });
    }

    handleMonthsChangeselect(e) {
        const selectedDurationMonths = e.target.value;
        this.setState({ Month: selectedDurationMonths });
    }

    handleQuarterChangeselect(e) {
        const selectedDurationQuarter = e.target.value;
        this.setState({ Quarter: selectedDurationQuarter });
        console.log(e.target.value)
    }

    handleHalfYearlyChange(e) {
        const selectedDurationHalf = e.target.value;
        this.setState({ HalfYearly: selectedDurationHalf });
        console.log(e.target.value)
    }

    // Deactivate language model popup
    DeleteClick(irn, isactive) {
        this.setState({ showHide2: !this.state.showHide2 });
        this.setState({ deleteirn: irn });
        this.setState({ activeflag: isactive });
    }

    // Activate language model popup
    handleModal1ShowHide(irn) {
        this.setState({ showHide: !this.state.showHide });
        this.setState({ deleteirn: irn });
        // this.setState({ activeflag: isactive });
    }
    handleChange1(e) {
        document
            .querySelectorAll(".error-border")
            .forEach((obj) => obj.classList.remove("error-border"));
        //if (this.state.surveytitle === undefined || this.state.surveytitle === "" || (this.state.surveytitle !== undefined && this.state.surveytitle.trim() === "")) {

        if (this.state.ButtonSave !== "Update") {
            if (!this.validate1()) {
                //   this.setState({ showHide2: this.state.showHide2 });
                // let value = document.getElementById('customerirn').value;

                // let data = this.state.customerList.filter(x => x.irn === value)[0];

                // //this.state.customerList.forEach(element => {
                // toast.error("For" + ' ' + data.customername + ' ' + " customer target was set already");
                // //});
                // this.setState({ customervalue: '' });

                // return true;
                // //  }
            }
            else {
                this.setState({ customervalue: e.target.irn });
                console.log(e.target.value);
            }
        }
        else {


            this.setState({ customervalue: e.target.irn });
            console.log(e.target.value);
        }

    }

    // used to set a new state for the input
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ target: value });
    };

    // delete  the particular target information by passing irn
    removeData() {
        this.setState({ isLoading: true });
        let req = {};
        req.irn = this.state.deleteirn
        console.log(req)
         postingservice.DeleteTarget(req).then((response) => {
        // handle success    
        this.setState({ showHide: !this.state.showHide })
        this.setState({ isLoading: false });
        // this.setState({ deletedata: response.data })
        toast.success("Deleted Successfully");

        // table data 
          postingservice.getTarget()
          .then((response) => {
              this.setState({ data: response.data });
            })
           .catch((error) => {
              this.setState({ isLoading: false });
          });
            }).catch((error) => {
        // handle error
        this.setState({ isLoading: false });
          toast.error("Not Deleted Successfully");
         });
        this.setState({ deletedata: this.state.deletedata })
        // this.setState({ email: '' })
    }


    EditClick(irn, customerirn, target, durationdescr, yearduration, subdurationdescr) {
        this.setState({ isLoading: true });
        document.getElementById("customerirn").disabled = true;
        this.setState({ ButtonSave: "Update" });
       
        this.setState({ irn: irn });
        this.setState({ customervalue: customerirn });
        // this.state.customerList[0].irn= customerirn
        this.setState({ target: target });
        this.setState({ Duration: durationdescr });
        console.log(durationdescr)
        console.log(subdurationdescr)
        if (durationdescr == "M") {
            this.setState({ Year: yearduration });
            this.setState({ Month: subdurationdescr.trim() });

        }
        else if (durationdescr == "Q") {
            this.setState({ Year: yearduration });
            this.setState({ Quarter: subdurationdescr });
            console.log(this.state.Year)
        }
        else if (durationdescr == "H") {
            this.setState({ Year: yearduration });
            this.setState({ HalfYearly: subdurationdescr });
            console.log(this.state.Year)
        }
        else if (durationdescr == "Y") {
            this.setState({ Year: yearduration });

            // this.setState({Year: yearduration});
        }

        this.setState({ isLoading: false });

        // })
        //  .catch((error) => {
        //handle error
        this.setState({ isLoading: false });
        // });
    }

    // validation for send email
    validate() {

        let isValid = true;
        document.querySelectorAll(".error-border").forEach(obj => obj.classList.remove("error-border"));
        let value = document.getElementById('customerirn').value;

        let data = this.state.customerList.filter(x => x.irn === value)[0];

        if (value === undefined || value === "" || value === "Select" || (value !== undefined && value.trim() === "")) {
            // this.setState({ showHide2: !this.state.showHide2 });
            document.getElementById("customerirn").classList.add("error-border");
            isValid = false;
        }

        if (this.state.target === '' || this.state.target === undefined) {
            // this.setState({ showHide2: !this.state.showHide2 });
            document.getElementById("txtTarget").classList.add("error-border");
            isValid = false;
        }
        if (this.state.Duration === '' || this.state.Duration === undefined || this.state.Duration === "Select") {
            // this.setState({ showHide2: !this.state.showHide2 });
            document.getElementById("ddlDuration").classList.add("error-border");
            isValid = false;
        }

        if (this.state.Duration === "M") {
            if (this.state.Year === '' || this.state.Year === undefined || this.state.Year === "Select") {
                document.getElementById("ddlYearlM").classList.add("error-border");
                isValid = false;
            }
            if (this.state.Month === '' || this.state.Month === undefined || this.state.Month === "Select") {
                document.getElementById("ddlYearlM1").classList.add("error-border");
                isValid = false;
            }
        }
        else if (this.state.Duration === "Q") {
            if (this.state.Year === '' || this.state.Year === undefined || this.state.Year === "Select") {
                document.getElementById("ddlYearlM2").classList.add("error-border");
                isValid = false;
            }
            if (this.state.Quarter === '' || this.state.Quarter === undefined || this.state.Quarter === "Select") {
                document.getElementById("ddlYearlMq").classList.add("error-border");
                isValid = false;
            }
        }
        else if (this.state.Duration === "H") {
            if (this.state.Year === '' || this.state.Year === undefined || this.state.Year === "Select") {
                document.getElementById("ddlYearlM3").classList.add("error-border");
                isValid = false;
            }
            if (this.state.HalfYearly === '' || this.state.HalfYearly === undefined || this.state.HalfYearly === "Select") {
                document.getElementById("ddlHalfYear").classList.add("error-border");
                isValid = false;
            }
        }
        else if (this.state.Duration === "Y") {
            if (this.state.Year === '' || this.state.Year === undefined || this.state.Year === "Select") {
                document.getElementById("ddlYearlM4").classList.add("error-border");
                isValid = false;
            }

        }


        return isValid;
    }

    validate1() {

        let isValid = true;
        document.querySelectorAll(".error-border").forEach(obj => obj.classList.remove("error-border"));
        let value = document.getElementById('customerirn').value;

        let data = this.state.customerList.filter(x => x.irn === value)[0];
        
            this.state.data.forEach(element => {
                if (value !== "Select") {
                if (element.customersirn == data.irn) {
                    document.getElementById("customerirn").classList.add("error-border");
                    isValid = false;
                }
            }
            });
        return isValid;
    }


    Save() {
        document
            .querySelectorAll(".error-border")
            .forEach((obj) => obj.classList.remove("error-border"));
        //if (this.state.surveytitle === undefined || this.state.surveytitle === "" || (this.state.surveytitle !== undefined && this.state.surveytitle.trim() === "")) {
      
        if (!this.validate()) {
            this.setState({ showHide2: this.state.showHide2 });
            toast.error("Please fill all required fields");
            return true;
            //  }
        }
        else {
         
            let value = document.getElementById('customerirn').value;
            let data = this.state.customerList.filter(x => x.irn === value)[0];
            let req = {};
            req.customersirn = data.irn;
            req.target = parseInt(this.state.target);
            req.duration = this.state.Duration;
            req.yearduration = parseInt(this.state.Year);
            req.subduration = this.state.Month;
            req.creationuserid = this.state.userid.userid;
            req.lastmoduserid = this.state.userid.userid;
            if (req.duration == "M") {
                req.subduration = this.state.Month;
            }
            else if (req.duration == "Q") {
                req.subduration = this.state.Quarter;
            }
            else if (req.duration == "H") {
                req.subduration = this.state.HalfYearly;
            }
            else if (req.duration == "Y") {
                req.subduration = "";
            }

        let searchData = this.state.data.filter(value => {
            return ( 
              !!value.customerirn.includes(req.customersirn) && value.duration.includes(req.duration) &&
             String( value.yearduration).includes(req.yearduration) && value.subduration.includes(req.subduration)
           
            );
          });
         
         

                if(searchData != "" )
                {
                    console.log(searchData)
                    console.log(searchData[0].irn)
                    console.log(searchData[0].customerirn)
                    console.log(searchData[0].target)
                    console.log(searchData[0].durationdescr)
                    console.log(searchData[0].yearduration)
                    console.log(searchData[0].subdurationdescr)

                    this.EditClick(searchData[0].irn, searchData[0].customerirn, searchData[0].target, searchData[0].durationdescr , searchData[0].yearduration , searchData[0].subdurationdescr )
                    toast.success('Customer Target Combination Exists.');
        
                }
                else
            {
            this.setState({ isLoading: true });
            // Add/Update Users information
            if (this.state.ButtonSave == "Save") {
                  postingservice.saveTarget(req)
                  .then((response) => {
                this.setState({ data: response.data });
                console.log(req);
                toast.success('Target Saved successfully.');
                     postingservice.getTarget()
                        .then((response) => {
                this.setState({ isLoading: false });
                       this.setState({ data: response.data });
                    })
                    .catch((error) => {
                this.setState({ isLoading: false });
                    });
                   })
                 .catch((error) => {
                     this.setState({ isLoading: false });
                 });

                 this.Reset();

              
            }
            else {
            this.setState({ isLoading: true });
            req.irn = this.state.irn;

               console.log("updatebutton")
                postingservice.UpdateTarget(req)
                .then((response) => {
                    this.setState({ data: response.data });
                    console.log(req);
                toast.success('Target Updated successfully.');
                     postingservice.getTarget()
                        .then((response) => {
                this.setState({ isLoading: false });
                this.setState({ data: response.data });
                    })
                    .catch((error) => {
                this.setState({ isLoading: false });
                    });
                 })
                .catch((error) => {
                   this.setState({ isLoading: false });
                 });

                 this.Reset();
            }
            }
        }
    }
    
    Reset() {
        // window.location.reload();
    document.getElementById('txtTarget').value = "";

    document.getElementById('customerirn').value = 'Select';
    document.getElementById('ddlDuration').value = 'Select';
    document.getElementById("customerirn").disabled = false;
    
    const req1 = {};
    req1.duration = this.state.Duration;
    this.setState({ isLoading: true });
    if (req1.duration == "M") {
    document.getElementById('ddlYearlM').value = 'Select';
    document.getElementById('ddlYearlM1').value = 'Select';
    }
    else if (req1.duration == "Q") {
    document.getElementById('ddlYearlM2').value = 'Select';
    document.getElementById('ddlYearlMq').value = 'Select';
    }
    else if (req1.duration == "H") {
        document.getElementById('ddlYearlM3').value = 'Select';
    document.getElementById('ddlHalfYear').value = 'Select';
    
    }
    else if (req1.duration == "Y") {
        document.getElementById('ddlYearlM4').value = 'Select';

    }

    this.setState({ ButtonSave: "Save" });
    this.setState({customervalue :""});
    this.setState({target :""});
    this.setState({Duration :""});
    this.setState({Year :""});
    this.setState({Month :""});
    this.setState({HalfYearly :""});
    this.setState({Quarter :""});
    this.setState({ isLoading: false });

    }

    render() {
        return (

            <LoadingOverlay active={this.state.isLoading} spinner >
                <div style={{ marginTop: "20px" }} ></div>
                <div className="mt-4">
                    <div className="row">
                        <div className="col-md-12 form-row pt-2">
                            <div className="ml-4 pl-2 pr-2 mr-4 w-100">

                                <div className="tab-content">

                                    <div
                                        role="tabpanel"
                                        className="tab-pane show active"
                                        id="tabOne"
                                    >
                                        <div className="pl-2">
                                            <div className=" mt-2">
                                                <form className="form-inline" action="">
                                                    <label htmlFor="email" className="mr-sm-2 mb-2">
                                                        Customer:<span className="req">*</span>{" "}
                                                    </label>

                                                    <select
                                                        className="form-control custom-form-control mb-2 mr-sm-4"
                                                        id="customerirn" 
                                                        value={this.state.customervalue}
                                                        onChange={(e) => this.handleChange1(e)}

                                                    >
                                                        <option value="Select">Select</option>
                                                        {this.state.customerList.map((item) => (
                                                            <option key={item.customername} value={item.irn}>
                                                                {item.customername}
                                                            </option>

                                                        ))}
                                                    </select>
                                                    <label htmlFor="Target" className="mr-sm-2 mb-2">
                                                        Target:<span className="req">*</span>{" "}
                                                    </label>
                                                    <input type="number" placeholder="Target" id="txtTarget" className='mr-sm-2 mb-2' pattern="[0-9]*" onChange={this.handleChange} value={this.state.target} />
                                                    <label htmlFor="Duration" className="mr-sm-2 mb-2">
                                                        Duration:<span className="req">*</span>{" "}
                                                    </label>
                                                    <select
                                                        className="form-control custom-form-control mb-2 mr-sm-4"
                                                        id="ddlDuration"
                                                        onChange={e => this.handleMonthChangeselect(e)}
                                                        value={this.state.Duration}
                                                    >
                                                        <option value="Select">Select</option>
                                                        <option value="M">Monthly</option>
                                                        <option value="Q">Quarterly</option>
                                                        <option value="H">Half Yearly</option>
                                                        <option value="Y">Yearly</option>
                                                    </select>
                                                </form>
                                            </div>


                                            <div>

                                                {
                                                    (() => {
                                                        switch (this.state.Duration) {
                                                            case "M":
                                                                return <div className=" mt-2" id="MonthDiv">
                                                                    <form className="form-inline" action="">
                                                                        <label htmlFor="Month" id="lblmonth" className="mr-sm-2 mb-2" >
                                                                            Monthly:&nbsp;&nbsp;<span className="req">*</span>{" "}
                                                                        </label>
                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            onChange={e => this.handleYearChangeselect(e)}
                                                                            id="ddlYearlM"
                                                                            value={this.state.Year}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            {this.state.TargetYear.map((item) => (
                                                                          <option key={item.targetyear} value={item.targetyear}>
                                                                              {item.targetyear}
                                                                          </option>

                                                        ))}
                                                                        </select>
                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            onChange={e => this.handleMonthsChangeselect(e)}
                                                                            id="ddlYearlM1"
                                                                            value={this.state.Month}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            <option value="1">January</option>
                                                                            <option value="2">February</option>
                                                                            <option value="3">March</option>
                                                                            <option value="4">April</option>
                                                                            <option value="5">May</option>
                                                                            <option value="6">June</option>
                                                                            <option value="7">July</option>
                                                                            <option value="8">August</option>
                                                                            <option value="9">September</option>
                                                                            <option value="10">October</option>
                                                                            <option value="11">November</option>
                                                                            <option value="12">December</option>
                                                                        </select>
                                                                    </form>
                                                                </div>

                                                                break;
                                                            case "Q":
                                                                return <div className=" mt-2" id="qtrDiv">
                                                                    <form className="form-inline" action="">

                                                                        <label htmlFor="Month" id="lblQtr" className="mr-sm-2 mb-2" >
                                                                            Quarterly:&nbsp;&nbsp;<span className="req">*</span>{" "}
                                                                        </label>
                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            onChange={e => this.handleYearChangeselect(e)}
                                                                            id="ddlYearlM2"
                                                                            value={this.state.Year}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            {this.state.TargetYear.map((item) => (
                                                                          <option key={item.targetyear} value={item.targetyear}>
                                                                              {item.targetyear}
                                                                          </option>

                                                        ))}
                                                                        </select>
                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            id="ddlYearlMq"

                                                                            onChange={e => this.handleQuarterChangeselect(e)}
                                                                            value={this.state.Quarter}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            <option value="Q1">Quarter 1</option>
                                                                            <option value="Q2">Quarter 2</option>
                                                                            <option value="Q3">Quarter 3</option>
                                                                            <option value="Q4">Quarter 4</option>
                                                                        </select>

                                                                    </form>
                                                                </div>
                                                                break;
                                                            case "H":
                                                                return <div className=" mt-2" id="HalfDiv">
                                                                    <form className="form-inline" action="">

                                                                        <label htmlFor="Month" id="lblHlfYrly" className="mr-sm-2 mb-2" >
                                                                            Half Yearly:&nbsp;&nbsp;<span className="req">*</span>{" "}
                                                                        </label>
                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            onChange={e => this.handleYearChangeselect(e)}
                                                                            id="ddlYearlM3"
                                                                            value={this.state.Year}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            {this.state.TargetYear.map((item) => (
                                                                          <option key={item.targetyear} value={item.targetyear}>
                                                                              {item.targetyear}
                                                                          </option>

                                                        ))}
                                                                        </select>
                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            id="ddlHalfYear"
                                                                            onChange={e => this.handleHalfYearlyChange(e)}
                                                                            value={this.state.HalfYearly}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            <option value="H1">First Half</option>
                                                                            <option value="H2">Second Half</option>
                                                                        </select>

                                                                    </form>
                                                                </div>
                                                                break;
                                                            case "Y":
                                                                return <div className=" mt-2" id="HalfDiv">
                                                                    <form className="form-inline" action="">

                                                                        <label htmlFor="Month" id="lblHlfYrly" className="mr-sm-2 mb-2" >
                                                                            Yearly:&nbsp;&nbsp;<span className="req">*</span>{" "}
                                                                        </label>

                                                                        <select
                                                                            className="form-control custom-form-control mb-2 mr-sm-4"
                                                                            onChange={e => this.handleYearChangeselect(e)}
                                                                            id="ddlYearlM4"
                                                                            value={this.state.Year}
                                                                        >
                                                                            <option value="Select">Select</option>
                                                                            {this.state.TargetYear.map((item) => (
                                                                          <option key={item.targetyear} value={item.targetyear}>
                                                                              {item.targetyear}
                                                                          </option>

                                                        ))}
                                                                        </select>

                                                                    </form>
                                                                </div>
                                                                break;
                                                            case "Select":
                                                                return <div >

                                                                </div>
                                                                break;
                                                            default: return null; break;
                                                        }
                                                    }).call(this)
                                                }

                                            </div>






                                        </div>
                                    </div>
                                    <div className="row" style={{ align: "right" }}>
                                    <div className="col-md-12 form-row pt-2">
                            <div className="ml-4 pl-2 pr-2 mr-4 w-100">

                                        <button
                                            className="btn btn-primary btn-sm mb-2 mr-sm-4"
                                            type="button"
                                            id="SaveButton"
                                            onClick={() => this.Save()}
                                        >
                                            {this.state.ButtonSave}
                                        </button>
                                        <button
                                            className="btn btn-primary btn-sm mb-2 mr-sm-4"
                                            type="button"
                                            id="resetButton"
                                            onClick={() => this.Reset()}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div></div>
                        </div>
                    </div>

                    <Modal
                        show={this.state.showHide}
                        centered
                        onHide={() => this.handleModal1ShowHide()}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton centered="true">
                            <Modal.Title>Confirm</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="" className="col-form-label mr-2 pt-1">
                                Are you sure you want to Delete?
                            </label>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.removeData()}
                            >
                                &nbsp; Yes &nbsp;
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => this.handleModal1ShowHide()}
                            >
                                No
                            </button>
                        </Modal.Footer>
                    </Modal>


                    <div className="col-md-12">
                        <div className="panel-bg  mt-2">
                            {" "}

                            <div className="panelTitle p-0 d-flex">
                                <div className="col-6 my-auto">
                                    <strong>Target</strong>
                                </div>
                                </div>

                            <div className="p-3 mb70" style={{ marginBottom: "180px" }}>
                                {/* <!--  Table Panel Start --> */}
                                <ToolkitProvider
                                    keyField="ReportId1"
                                    data={this.state.data}
                                    columns={this.columns}
                                >
                                    {(props) => (
                                        <div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                pagination={paginationFactory({ ...options })}
                                                noDataIndication={() => <NoDataIndication />}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                                {/* table panel ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}
