import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import postingservice from '../../services/postingservice.js';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import commondataservice from '../../services/commondataservice.js';

const NoDataIndication = () => (
    <div className="spinner">
        <div className="text-center">No Records Found</div>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
    </div>
);

let options = {
    showTotal: true,
    sizePerPageList: [{
        text: '25', value: 25
    }, {
        text: '50', value: 50
    }, {
        text: '75', value: 75
    }]
};

let postingirnarray = [];


export default class Usernotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateResultCompleted: null,
            dateResultCompleted1: null,
            isLoading: true,
            affiliatesList: [],
            affiliatesListAdd: [],
            UsersList: [],
            gridData: [],
            isAdminUSer: false,
            userAffiliates: [],
            userirn: '',
            data: [],
            emaildata: [],
            firstname: '',
            emailiddata: '',
            usersirndata: '',
            customernamedata: '',
            setSelectedCountry: "",
            setCities: "",
            setSelectedCity: "",
            selectedCounty: "",
            showHide4: false,
            showHide2: false,
            showHideSA: false,
            showHide: false,
            email: "",
            emailid: "",
            isHidden: true,
            deletedata: [],
            deleteirn: '',
            activeflag: '',
            irn: '',
            affiliatesirn: '',
            value: '',
            userid: '',
            useriddata: '',
            customervalue: "",
            customervalueAdd: "",
            customervalueEmail: "",
            isactive:"",
            multiselect: [],
            isua: ''
            

        };
        let datauser = commondataservice.getUserlogin();
        this.setState({ userid: datauser.userid });
      
        const handleCheck = (event) => {
            // var updatedList = [...checked];
            // if (event.target.checked) {
            //   updatedList = [...checked, event.target.value];
            // } else {
            //   updatedList.splice(checked.indexOf(event.target.value), 1);
            // }
            // setChecked(updatedList);
          };

        const req = {};
        
        req.issearch = true;
        req.affiliate = 'Select';
        req.urin = 'Select';

         postingservice.getUsersData(req)
         .then((response) => {
            console.log(response)
             //handle success
             this.setState({ data: response.data });
             this.setState({ isLoading: false });
         })
         .catch((error) => {
             //handle error
             console.log(error)
             this.setState({ isLoading: false });
         });

         postingservice.getUsersDataNew()
         .then((response) => {
            console.log(response)
             //handle success
             this.setState({ UsersList: response.data });
             this.setState({ isLoading: false });
         })
         .catch((error) => {
             //handle error
             this.setState({ isLoading: false });
         });
     

         // get affiliates list
      postingservice.getAllAffiliates()
      .then((response) => {
        // handle success
        console.log(response.data)
        let affiliates = response.data;
          this.setState({ affiliatesList: affiliates });
          this.setState({ affiliatesListAdd: affiliates });
        console.log(this.state.affiliatesList)
          
      })
      .catch((error) => {
        //handle error
      });

      
    }

    // define table columns
    columns = [
        {
            dataField: "",
            align: 'center',
            headerAlign: (column, colIndex) => 'center',
            formatter: (cellContent, row) => {
                if (row.isactive === '0') {
                    return (
                        <input type="checkbox" id='chkmulti' value={this.state.multiselect}
                        onChange={e => this.handleCheckboxChange(e, row)} />
                    )
                }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "2%" };
            }
        },
        {
            dataField: 'affiliatename', text: 'Affiliate', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "18%" };
            }
        },
        {
            dataField: 'emailid', text: 'Email ID', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "18%" };
            }
        },
        {
            dataField: "",
            text: 'Actions',
            align: 'center',
            headerAlign: (column, colIndex) => 'center',
            formatter: (cellContent, row) => {
                if (row.isactive === '1') {
                    return (
                        <button type="button" className="btn btn-outline-success" onClick={() => this.handleModal1ShowHide(row.irn,row.affiliatesirn, row.isactive, row.isua)}>&nbsp;&nbsp;Enable Notification&nbsp;&nbsp;</button>

                    )
                }
                else {
                    return (
                        <button type="button" className="btn btn-outline-danger" onClick={() => this.handleModalShowHide(row.irn,row.affiliatesirn,row.emailid,row.isactive, row.isua)}>Disable Notification</button>
                    )
                }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" };
            }
        }
    ];

    // sort data
    sortIt = sortBy => (a, b) => {
        if (a[sortBy] > b[sortBy]) {
            return -1;
        } else if (a[sortBy] < b[sortBy]) {
            return 1;
        }
        return 0;
    }


     handleCheckboxChange = (event, datarow) => {
        let check = event.target.checked;
        if(check==true){
            var obj = { "irn": datarow.irn,"emailid": datarow.emailid,"affiliatesirn": datarow.affiliatesirn,"isua": datarow.isua  }
            postingirnarray.push(obj);
      }
      else{
        postingirnarray = postingirnarray.filter((item => item.irn !== datarow.irn) && (item1 => item1.emailid !== datarow.emailid) 
            && (item2 => item2.affiliatesirn !== datarow.affiliatesirn));
            
    }
    console.log(postingirnarray)

    }


    // onchange events starts


    // Deactivate language model popup
    handleModal1ShowHide(irn,affiliatesirn, isactive, isua) {
        console.log(isua)
        this.setState({ showHide2: !this.state.showHide2 });
        this.setState({ activeflag: isactive });
        this.setState({ irn: irn });
        this.setState({ affiliatesirn: affiliatesirn });
        this.setState({ isua: isua });
    }

    // Activate language model popup
    handleModalShowHide(irn,affiliatesirn,emailid,isactive,isua) {
        this.setState({ showHide: !this.state.showHide });
        this.setState({ activeflag: isactive });
        this.setState({ irn: irn });
        this.setState({ affiliatesirn: affiliatesirn });
        this.setState({ emailid: emailid });
        this.setState({ isua: isua });
    }

      // Submit All  model popup
      handleModalSAShowHide() {
        this.setState({ showHideSA: !this.state.showHideSA });
        this.setState({ activeflag: '1' });
    }

    // used to set a new state for the input
    handleChange = (event) => {
        console.log(event)
        const { name, value } = event.target;
        console.log(event.target)

        this.setState({ [name]: value });
    };

      // Add User model popup
      handleModal4ShowHide() {
        this.setState({ showHide4: !this.state.showHide4 });
        this.setState({ email: '' });
        this.state.isHidden = true;
    }

    handleChange1(e) {
        this.setState({ irn: e.target.value });
        
    }

    affaddonchange(e) {
        document.querySelectorAll(".error-border")
        .forEach((obj) => obj.classList.remove("error-border"));

    }

    Emailddonchange(e) {
        document.querySelectorAll(".error-border")
        .forEach((obj) => obj.classList.remove("error-border"));

    }

    handleChange2(e) {
        this.setState({ affiliatesirn: e.target.value });
        var index = e.nativeEvent.target.selectedIndex;
        this.setState({ customervalueEmail: e.nativeEvent.target[index].text });
        this.setState({ customervalueAdd: e.target.value });

    }

    //Add User from Popup
    AddUserNew(){

        let Srchaff = document.getElementById('affiliatesirnAdd').value;
        let Srchemail = document.getElementById('EmailidAdd').value;

        var e = document.getElementById("EmailidAdd");
        var Srchemailtext=e.options[e.selectedIndex].text;
       
        let req = {};
        req.affiliateirn = Srchaff;
        req.customersirn = Srchemail;
        req.creationuserid = this.state.userid.userid;
        req.lastmoduserid = this.state.userid.userid;
        req.isactive = '0'
        req.email = Srchemailtext;
        console.log(req.customersirn);
        console.log(req.affiliateirn);
        console.log(req.email);
        if(req.customersirn !="Select" && req.affiliateirn !="All" )
        {
            this.setState({ showHide4: !this.state.showHide4 });
            this.setState({ email: '' });
            this.state.isHidden = false;
          
            postingservice.saveUserAlert(req).then((response) => {
                // handle success    
                this.setState({ showHide: !this.state.showHide })
                this.setState({ isLoading: false });
                toast.success("Enabled Successfully");
                this.setState({ isHidden: true })

                let Srchaff = document.getElementById('affiliatesirnnew').value;
                let Srchemail = document.getElementById('Emailid').value;
    
                const req = {};
            
                req.issearch = true;
                req.affiliate = Srchaff;
                req.urin = Srchemail;
                // table data 
                postingservice.getUsersData(req)
                    .then((response) => {
                        this.setState({ data: response.data });
                    })
                    .catch((error) => {
                        this.setState({ isLoading: false });
                    });
            }).catch((error) => {
                // handle error
                this.setState({ isLoading: false });
                toast.error("Not Enabled Successfully");
            });
            this.setState({ deletedata: this.state.deletedata })
            this.setState({ irn: '' })
            this.setState({ affiliatesirn: '' })
            this.setState({ emailid: '' })
        }
        else{
            if(Srchaff =="All" )
            document.getElementById("affiliatesirnAdd").classList.add("error-border");

            if(Srchemail =="Select" )
            document.getElementById("EmailidAdd").classList.add("error-border");

            toast.error("Please enter details.");

        }
    }

    Search(){

        let Srchaff = document.getElementById('affiliatesirnnew').value;
        let Srchemail = document.getElementById('Emailid').value;

        const req = {};
        req.issearch = true;
        req.affiliate = Srchaff;
        req.urin = Srchemail;

         postingservice.getUsersData(req)
         .then((response) => {
            console.log(response)
             //handle success
             this.setState({ data: response.data });
             this.setState({ isLoading: false });
         })
         .catch((error) => {
             //handle error
             this.setState({ isLoading: false });
         });
        console.log(Srchaff)
        console.log(Srchemail)
    }
    

    Reset() {

    document.getElementById('affiliatesirnnew').value = 'Select';
    document.getElementById('Emailid').value = 'Select';
    }

    SubmitAll() {
        this.setState({ showHideSA: !this.state.showHideSA });
        this.setState({ activeflag: '1' });

    }

    // Deactivate the user
    InsertData1() {
        let req = {};
        req.customersirn = this.state.irn;
        req.affiliateirn = this.state.affiliatesirn;
        req.creationuserid = this.state.userid.userid;
        req.lastmoduserid = this.state.userid.userid;
        req.isactive = '1'
        req.email = this.state.emailid;
        let isua = this.state.isua;
        console.log(isua)
        if(isua!= 'yes')
        {
        postingservice.saveUserAlert(req).then((response) => {
            // handle success    
            this.setState({ showHide: !this.state.showHide })
            this.setState({ isLoading: false });
            toast.success("Deactivated Successfully");
            this.setState({ isHidden: true })

            let Srchaff = document.getElementById('affiliatesirnnew').value;
            let Srchemail = document.getElementById('Emailid').value;

            const req = {};
            req.issearch = true;
            req.affiliate = Srchaff;
            req.urin = Srchemail;

            // table data 
            postingservice.getUsersData(req)
                .then((response) => {
                    this.setState({ data: response.data });
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                    toast.success("Not Deactivated Successfully");
                });
        }).catch((error) => {
            // handle error
            this.setState({ isLoading: false });
        });
        this.setState({ deletedata: this.state.deletedata })
        this.setState({ irn: '' })
        this.setState({ affiliatesirn: '' })
        this.setState({ emailid: '' })
    }
    else{
        this.removeData();
    }
    }

        // Enable the user
        removeData() {
            let req = {};
            req.customersirn = this.state.irn;
            req.affiliateirn = this.state.affiliatesirn;
            postingservice.DeleteUserAlert(req).then((response) => {
                // handle success    
                this.setState({ showHide2: !this.state.showHide2 })
                this.setState({ isLoading: false });
                toast.success("Enabled Successfully");
                this.setState({ isHidden: true })

                let Srchaff = document.getElementById('affiliatesirnnew').value;
                let Srchemail = document.getElementById('Emailid').value;
    
                const req = {};
                req.issearch = true;
                req.affiliate = Srchaff;
                req.urin = Srchemail;
                // table data 
                postingservice.getUsersData(req)
                    .then((response) => {
                        this.setState({ data: response.data });
                    })
                    .catch((error) => {
                        this.setState({ isLoading: false });
                    });
            }).catch((error) => {
                // handle error
                this.setState({ isLoading: false });
                toast.error("Not Enabled Successfully");
            });
            this.setState({ deletedata: this.state.deletedata })
            this.setState({ email: '' })
        }

        InsertData1SA(){
            // Disable all
            this.setState({ isLoading: true });

            for(var i=0;i<postingirnarray.length;i++)
            {
            let req = {};
            req.customersirn = postingirnarray[i].irn;
            req.affiliateirn = postingirnarray[i].affiliatesirn;
            req.creationuserid = this.state.userid.userid;
            req.lastmoduserid = this.state.userid.userid;
            req.isactive = '1'
            req.email = postingirnarray[i].emailid;
          
            let isua = postingirnarray[i].isua;
            console.log(isua)
            if(isua!= 'yes')
            {
             postingservice.saveUserAlert(req).then((response) => {
                // handle success    
            
            }).catch((error) => {
                // handle error
                this.setState({ isLoading: false });
                toast.success("Not Deactivated Successfully");
            });
          
        }
        }
        toast.success("Deactivated Successfully");

        this.setState({ showHide: !this.state.showHide })
        this.setState({ isLoading: false });
        this.setState({ isHidden: true })
        
        let Srchaff = document.getElementById('affiliatesirnnew').value;
        let Srchemail = document.getElementById('Emailid').value;

        setTimeout(() => {
            this.setState({ isLoading: true });
        
        const req = {};
        
        req.issearch = true;
        req.affiliate = Srchaff;
        req.urin = Srchemail;
        // table data 
        postingservice.getUsersData(req)
            .then((response) => {
                 this.setState({ data: response.data });
                console.log(this.state.data)

            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
        postingirnarray=[];
        this.setState({ deletedata: this.state.deletedata })
        this.setState({ irn: '' })
        this.setState({ affiliatesirn: '' })
        this.setState({ emailid: '' })
      
        this.setState({ isLoading: false });
    }, 3000);
    this.setState({ showHideSA: !this.state.showHideSA });
    this.setState({ activeflag: '1' });
    }
    
    //     InsertData1SA(){
    //         console.log(postingirnarray)

    //         for(var i=0;i<postingirnarray.length;i++)
    //         {
    //             console.log(i)
    //         let req = {};
    //         req.customersirn = postingirnarray[i].irn;
    //         req.affiliateirn = postingirnarray[i].affiliatesirn;
    //         req.creationuserid = this.state.userid.userid;
    //         req.lastmoduserid = this.state.userid.userid;
    //         req.isactive = '1'
    //         req.email = postingirnarray[i].emailid;
          
    //         let isua = postingirnarray[i].isua;
    //         console.log(isua)
    //         if(isua!= 'yes')
    //         {
    //         postingservice.saveUserAlert(req).then((response) => {
    //             // handle success    
            
    //         }).catch((error) => {
    //             // handle error
    //             this.setState({ isLoading: false });
    //         });
          
    //     }
    //     }
    //     this.setState({ showHide: !this.state.showHide })
    //     this.setState({ isLoading: false });
    //     this.setState({ isHidden: true })
    //     // table data 
    //     postingservice.getUsersData()
    //         .then((response) => {
    //             this.setState({ data: response.data });
    //         })
    //         .catch((error) => {
    //             this.setState({ isLoading: false });
    //             toast.success("Not Deactivated Successfully");
    //         });
    //     toast.success("Deactivated Successfully");
    //     postingirnarray=[];
    //     this.setState({ deletedata: this.state.deletedata })
    //     this.setState({ irn: '' })
    //     this.setState({ affiliatesirn: '' })
    //     this.setState({ emailid: '' })
    //     this.setState({ showHideSA: !this.state.showHideSA });
    //     this.setState({ activeflag: '1' });
    // }

    render() {
        return (

            <LoadingOverlay active={this.state.isLoading} spinner >
                <div style={{ marginTop: "20px" }} ></div>
                <div className="mt-4">
                    {this.state.activeflag === '1' ?
                        <Modal
                            show={this.state.showHide2}
                            centered
                            onHide={() => this.handleModal1ShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to Enable Notification?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.removeData()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    onClick={() => this.handleModal1ShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                        :
                        <Modal
                            show={this.state.showHide}
                            centered
                            onHide={() => this.handleModalShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to  Disable Notification ?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    id='activateDeactivateYesButton2'
                                     onClick={() => this.InsertData1()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    id='activateDeactivateNoButton2'
                                    onClick={() => this.handleModalShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                    {/* Submitall popups */}
                    {this.state.activeflagNew === '1' ?
                        <Modal
                            show={this.state.showHide2}
                            centered
                            onHide={() => this.handleModalShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to Enable Notification?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.removeData()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    onClick={() => this.handleModal1ShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                        :
                        <Modal
                            show={this.state.showHideSA}
                            centered
                            onHide={() => this.handleModalSAShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to  Disable All Notification ?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    id='activateDeactivateYesButton2'
                                     onClick={() => this.InsertData1SA()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    id='activateDeactivateNoButton2'
                                    onClick={() => this.handleModalSAShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                    <div className="col-md-12">
                        <div className="panel-bg  mt-2">
                            {" "}

                            <div className="panelTitle p-0 d-flex">
                                <div className="col-6 my-auto">
                                    <strong>User Notification</strong>
                                </div>
                                <div className="col-6 pl-2 pr-1 pt-1 pb-2 my-auto text-right">
                                    <button className="btn btn-primary" onClick={() => this.handleModal4ShowHide()}>&nbsp;Add User&nbsp;</button>
                                    <Modal
                                        dialogClassName="modal-60w"
                                        show={this.state.showHide4}
                                        onHide={() => this.handleModal4ShowHide()}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton centered="true">
                                            <Modal.Title>Add User</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row mb-4">
                                                <div className="col-sm-12 ml-2">
                                                    <form className="form-inline">
                                                        <div className="form-group mt-2 col-sm-12">
                        {/* Add User Popup */}
                          <label htmlFor="emailid" className="mr-sm-2 mb-2">
                          Affiliate&nbsp;:&nbsp;<span className="req">*</span>{" "}
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="affiliatesirnAdd" onChange={(e) => this.affaddonchange(e)}
                        >
                          <option value="All">All</option>
                          {this.state.affiliatesListAdd.map((item) => (
                            <option key={item.affiliatename} value={item.irn}>
                              {item.affiliatename}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Email ID&nbsp;:&nbsp;<span className="req">*</span>{" "}
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="EmailidAdd" onChange={(e) => this.Emailddonchange(e)}
                        >
                          <option value="Select">Select</option>
                          {this.state.UsersList.map((item) => (
                            <option key={item.emailid} value={item.irn}>
                              {item.emailid}
                            </option>
                          ))}
                        </select>
                         <button type="button" className="btn btn-primary  mb-2 mr-sm-4 ml-4 mt-2 mb-0"
                           onClick={() => this.AddUserNew()}>Add User</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                                </div>

                                <div className="pl-2">
                    <div className=" mt-2">
                      <form className="form-inline" action="">
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Affiliate:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="affiliatesirnnew"
                        >
                          <option value="Select">Select</option>
                          {this.state.affiliatesList.map((item) => (
                            <option key={item.affiliatename} value={item.irn}>
                              {item.affiliatename}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Email ID:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="Emailid"
                        >
                          <option value="Select">Select</option>
                          {this.state.UsersList.map((item) => (
                            <option key={item.emailid} value={item.irn}>
                              {item.emailid}
                            </option>
                          ))}
                        </select>
                        <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          id="filterGoButton"
                          onClick={()=> this.Search()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          id="resetButton"
                          onClick={() => this.Reset()}
                        >
                          Reset
                        </button>

                         <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          id="SubmitButton"
                          onClick={() => this.SubmitAll()}
                        >
                          Disable All
                        </button>
                      </form>
                      <div>
                     
                      </div>
                    </div>
                  </div> 
                            <div className="p-3 mb70" style={{ marginBottom: "180px" }}>
                                {/* <!--  Table Panel Start --> */}
                                <ToolkitProvider
                                    keyField="ReportId"
                                    // data={this.state.data.sort(this.sortIt('isactive'))}
                                    data={this.state.data}
                                    columns={this.columns}
                                >
                                    {(props) => (
                                        <div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                pagination={paginationFactory({ ...options })}
                                                noDataIndication={() => <NoDataIndication />}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                                {/* table panel ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    
}
